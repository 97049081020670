<template>
  <v-card :loading="loading" class="ma-auto" tile>
    <template v-if="selectedAss.AllAss">
      <v-alert type="info">No Information for a management company</v-alert>
    </template>
    <template v-else>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Information data</v-toolbar-title>
        <v-spacer />
        <export_to_pdf_btn
          :custom_id="customId"
          :html_element="el"
          :name="'Information of' + selectedAss.Client_Associations"
        ></export_to_pdf_btn>
        <print_button :html_element="el" :custom_id="customId"></print_button>
      </v-toolbar>
      <v-card-text class="pb-1 pt-1" :id="customId" ref="info_card">
        <v-card-subtitle class="pb-1">{{
            styledInfo.firstParagraph
          }}</v-card-subtitle>
        <v-card-subtitle class="pb-1 pt-1">{{
            styledInfo.socendParagraph
          }}</v-card-subtitle>
        <v-card-subtitle class="pb-1 pt-1">{{
            styledInfo.thirdParagraph
          }}</v-card-subtitle>
        <v-data-table
          :headers="headers"
          hide-default-footer
          :items="items"
          class="elevation-1 mb-3 mt-3"
        ></v-data-table>
        <!--      <v-list subheader>-->
        <!--        <v-subheader>{{ styledInfo.beforeList }}</v-subheader>-->
        <!--        <v-list-item v-for="(item, index) in styledInfo.listText" :key="index">-->
        <!--          <v-list-item-title>{{ index }}:</v-list-item-title>-->
        <!--          <v-list-item-subtitle>{{ item }}</v-list-item-subtitle>-->
        <!--        </v-list-item>-->
        <!--      </v-list>-->
        <v-card-actions class="pb-1 pt-1">{{
            styledInfo.lastText
          }}</v-card-actions>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "info_card",
  components: {
    print_button: () => import("@/components/print_button"),
    export_to_pdf_btn: () => import("@/components/export_to_pdf_btn")
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    customId: null
  }),
  beforeMount() {
    this.customId =
      "asd5as1d3asd13as1d3asd13a2" + this.getRandomColor().replace(" ", "_");
  },
  computed: {
    ...mapGetters({
      selectedAss: "lauderGetSelectedAss"
    }),
    el() {
      let el = this.$refs.info_card;
      return el ? el : document.getElementById(this.customId);
    },
    headers() {
      let arr = [];
      for (const key in this.styledInfo.listText) {
        arr.push({
          text: key,
          value: key,
          sortable: false,
          class: "secondary white--text"
        });
      }
      return arr;
    },
    items() {
      let ret = {};
      for (const key in this.styledInfo.listText) {
        ret[key] = this.styledInfo.listText[key];
      }
      return [ret];
    },
    styledInfo() {
      let unformatedText = this.info;
      function replacment(val) {
        unformatedText = unformatedText.replace(val, "");
        if (unformatedText[0] === "." || unformatedText[0] === ":") {
          unformatedText = unformatedText
            .slice(1, unformatedText.length)
            .trim();
        }
        unformatedText.trim();
      }
      if (!unformatedText) return {};
      let firstParagraph = unformatedText?.split("There")[0];
      replacment(firstParagraph);
      firstParagraph = firstParagraph.trim();
      firstParagraph =
        firstParagraph.substring(0, firstParagraph.length - 1).trim() + ".";
      let socendParagraph = unformatedText.slice(
        0,
        unformatedText.indexOf(".") + 1
      );
      replacment(socendParagraph);
      let thirdParagraph = unformatedText.slice(
        0,
        unformatedText.indexOf(".") + 1
      );
      replacment(thirdParagraph);
      thirdParagraph =
        thirdParagraph +
        " " +
        unformatedText.slice(0, unformatedText.indexOf(".") + 1);
      replacment(unformatedText.slice(0, unformatedText.indexOf(".") + 1));
      let beforeList = unformatedText.slice(0, unformatedText.indexOf(":"));
      replacment(beforeList);
      let listText = {};
      function formatIt(text) {
        return { label: text.split(":")[0], value: text.split(":")[1] };
      }
      const s = formatIt(
        unformatedText.slice(0, unformatedText.indexOf("Late Fees:"))
      );
      listText[s.label] = s.value;
      replacment(unformatedText.slice(0, unformatedText.indexOf("Late Fees:")));
      const a = formatIt(
        unformatedText.slice(0, unformatedText.indexOf("Interest:"))
      );
      listText[a.label] = a.value;
      replacment(unformatedText.slice(0, unformatedText.indexOf("Interest:")));
      const b = formatIt(
        unformatedText.slice(
          0,
          unformatedText.indexOf("Accelerated Assessments:")
        )
      );
      listText[b.label] = b.value;
      replacment(
        unformatedText.slice(
          0,
          unformatedText.indexOf("Accelerated Assessments:")
        )
      );
      const c = formatIt(
        unformatedText.slice(0, unformatedText.indexOf("The community"))
      );
      listText[c.label] = c.value;
      replacment(
        unformatedText.slice(0, unformatedText.indexOf("The community"))
      );
      let lastText = unformatedText;
      lastText = lastText.substring(0, lastText.length - 1).trim() + ".";
      // console.log({
      //   firstParagraph,
      //   socendParagraph,
      //   thirdParagraph,
      //   beforeList,
      //   listText,
      //   lastText
      // });
      return {
        firstParagraph,
        socendParagraph,
        thirdParagraph,
        beforeList,
        listText,
        lastText
      };
    }
  }
};
</script>

<style scoped></style>
