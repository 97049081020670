<template>
  <v-container>
    <!--    <dis-assoc-drop-->
    <!--      v-if="isMgmt"-->
    <!--      :outlined="false"-->
    <!--      filled-->
    <!--      background="white"-->
    <!--      color="primary"-->
    <!--      class="mb-2"-->
    <!--      @changed="fetchInformationData(true)"-->
    <!--    ></dis-assoc-drop>-->
    <Info_card class="mb-2" :info="info" :loading="loading"></Info_card>
    <status_list />
  </v-container>
</template>

<script>
//todo::status order
import { mapGetters } from "vuex";
import storage from "@/storage";
import { lauderLarken } from "@/plugins/axios";
import { handleLauderLarkinRequest } from "@/errors";
import Info_card from "@/components/info_card";
export default {
  name: "information",
  components: {
    Info_card,
    status_list: () => import("@/components/status_list")
  },
  data: () => ({
    loading: false,
    info: ""
  }),
  computed: {
    show() {
      if (this.isMgmt && this.selectedAss) {
        return true;
      } else return !this.isMgmt;
    },
    el() {
      let el = this.$refs.exported;
      return el ? el : document.getElementById(this.customId);
    },
    ...mapGetters({
      isMgmt: "isMgmt",
      token: "customToken",
      selectedAss: "lauderGetSelectedAss",
      db: "laudergetdb",
      loginState: "laudergetLoginState"
    })
  },
  methods: {
    async fetchInformationData() {
      if ((this.isMgmt && this.selectedAss.AllAss) || !this.loginState) return;
      this.loading = true;
      try {
        const searchForOld = await storage.getInformationData(
          this.filterAssName(this.token),
          this.db
        );
        if (searchForOld) {
          this.info = searchForOld;
        } else {
          let result = await lauderLarken.getInformationData(this.filterAssName(this.token));
          let data = handleLauderLarkinRequest(result);
          data = data[0]["InfoData"];
          await storage.setInformationData(data,this.filterAssName( this.token), this.db);
          this.info = data;
        }
      } catch (e) {
        let dt = await storage.getInformationData(this.filterAssName(this.token), this.db);
        if (dt) {
          this.info = dt;
        } else {
          this.info = null;
          console.log(e);
        }
      }
      this.loading = false;
      // this.loading = true;
      // this.contact = await lauderlarkin_storage.getContactsData(
      //   this.selectedAss["Client_Associations"],
      //   this.db
      // );
      // if (!this.contact) {
      //   await this.$store.dispatch("loadContacts");
      //   await this.fetchContacts();
      // }
      // this.loading = false;
      // this.loading = true;
      // if (!this.info || x) {
      //   await this.$store.dispatch("addRequest", "fetchInformationData");
      // }
      // this.loading = false;
    }
  },
  watch: {
    selectedAss: {
      immediate: true,
      handler() {
        this.fetchInformationData();
      }
    }
  }
};
</script>

<style scoped></style>
